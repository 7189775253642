import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { Box } from 'rebass/styled-components';
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';
import GhostLink from '../components/Buttons/GhostLink';

const BackButton = ({ slug }) => (
  <Box mb={4}>
    <GhostLink to={`/chaloupky/${slug}/`}>Zpět</GhostLink>
  </Box>
);

const RoomTypeGalleryPage = ({ data }) => {
  const { wordpressWpMphbRoomType: page, site } = data;
  const { photogallery } = page.acf;

  return (
    <Layout>
      <Helmet title={`${page.title} | ${site.siteMetadata.title}`} />

      <Section>
        <Container>
          <BackButton slug={page.slug} />

          {photogallery && photogallery.map(photo => (
            <Box mb={2} key={photo.localFile.id}>
              <Img loading="lazy" fadeIn={false} fluid={photo.localFile.childImageSharp.fluid} alt={photo.alt_text} />
            </Box>
          ))}

          <BackButton slug={page.slug} />
        </Container>
      </Section>
    </Layout>
  );
};

RoomTypeGalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RoomTypeGalleryPage;

export const pageQuery = graphql`
  query RoomTypeGalleryById($id: String!) {
    wordpressWpMphbRoomType(id: { eq: $id }) {
      title
      slug
      acf {
        photogallery {
          alt_text
          localFile {
            name
            id
            childImageSharp {
              fluid(maxWidth: 1280, maxHeight: 960, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
